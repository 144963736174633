import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[ContentPage] action.mount - data:%o', data);

		// Get articles
		if (data.hasOwnProperty('id')) {
			//logger.log('[ContentPage] action.mount - ensureConfig');
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let path = '';
				let prevNextPath = '';

				if (data.category) {
					data.landing = '';
					switch (data.category) {
						case 'articles':
							let docId = data.docId ? data.docId : data.id;
							let contentId = data.date ? data.docId ? 'byId/' + data.date + '/' + docId : data.date + '/' + docId : docId;
							logger.log('[ContentPage] action.mount - conentId :%o', contentId)
							path = Config.cmsData.news.replace('<contentId>', contentId);
							logger.log('[ContentPage] action.mount - path :%o', path)
							prevNextPath = Config.relatedContent.nextPrevious
								.replace('<contentId>', data.id)
								.replace('<count>', 1)
								.replace('<true|false>', false)
								.replace('<type>', 'news');
							break;
						case 'interviews':
							let interviewId = data.date ? data.date + '/' + data.id : data.id;
							path = Config.cmsData.interviews.replace('<contentId>', interviewId);
							prevNextPath = Config.relatedContent.nextPrevious
								.replace('<contentId>', data.id)
								.replace('<count>', 1)
								.replace('<true|false>', false)
								.replace('<type>', 'interviews');
							break;
						default:
							break;
					}
				} else if (data.landing) {
					data.category = '';
					//logger.log('[ContentPage] action.mount - landing - data: %o', data);
					switch (data.landing) {
						case 'tickets':
						case 'privacy':
						case 'inpursuitofgreatness':
							path = Config.cmsData.landing.replace('<section>', data.landing);
							break;
						case 'visit':
						case 'landing':
							path = Config.cmsData.landing.replace('<section>', data.id);
							break;
						default:
							break;
					}
				}

				if (data.prefix === 'preview') {
					path = '/preview' + path;
				}

				data.dataUrl = path;
				data.prevNextUrl = prevNextPath;
				data.cmsData = Config.cmsData;
				data.relatedContent = Config.relatedContent;
				data.sponsors = Config.sponsors;
				data.adConfig = Config.adConfig;
				//logger.log('[ContentPage] action.mount - data: %o', data);
				dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: data });
			});
		}
	},
	unmount: () => (dispatch, getState, store) => {
		let data = {
			category: '',
			landing: '',
			dataUrl: '',
		};
		dispatch({ type: deps.actionTypes.CONTENT_UNMOUNT, data: data });
	},
};
